/* 
 * @Author: 曹俊杰 
 * @Date: 2022-06-08 10:37:30
 * @Module: 我的曝光
 */
 <template>
  <div style="padding:0 24px">
    <exposureList @onDelete="onDelete" showDelete :search.sync="search" :tableData="tableData" @onSearch="onSearch" />
    <hdq-pagination small ref="pagination" @change="paginationChange" style="padding-bottom:10px;" />
  </div>
</template>
 <script>
import exposureList from "./components/exposureList"
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: {
    exposureList,
    hdqPagination
  },

  data () {
    return {
      search: '',
      tableData: [],
    };
  },
  mounted () {
    this.getExposureList()
  },
  methods: {
    onSearch () {
      this.$refs.pagination.createPagination({ current: 1 })
      this.getExposureList()
    },
    async getExposureList () {
      const { current, size } = this.$refs.pagination;
      const keyword = this.search;
      const { status, data } = await this.$api.getExposureList({ current, size, keyword, type: 1 });
      if (status === 200) {
        const { records } = data;
        this.tableData = records;
        this.$refs.pagination.createPagination(data);
      }
    },
    paginationChange ({ current, size }) {
      this.getExposureList(current, size)
    },
    async onDelete ({ row }) {
      try {
        await this.$confirm('删除后不可恢复，是否确定删除此内容？', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteExposure(row)
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          const index = this.tableData.findIndex(item => item.uuid == row.uuid);
          this.tableData.splice(index, 1)
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (res) {
        console.log(res)
      }
    }
  }
};
 </script>
 <style lang='scss' scoped>
</style>